import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class EventsMixin extends Vue {
  get searchText() {
    return this.$store.state.events.searchText || '';
  }

  set searchText(value: string) {
    this.$store.commit('events/setSearchText', value);
  }

  get showImportEvents() {
    return this.$store.state.events.showImportEvents;
  }

  set showImportEvents(value: boolean) {
    this.$store.commit('events/setShowImportEvents', value);
  }

  get showExportEvents() {
    return this.$store.state.events.showExportEvents;
  }

  set showExportEvents(value: boolean) {
    this.$store.commit('events/setShowExportEvents', value);
  }

  get selectedRows() {
    return this.$store.state.events.selectedRows || [];
  }

  set selectedRows(value: Array<any>) {
    this.$store.commit('events/setSelectedRows', value);
  }

  get hasSelectedRows() {
    return CommonUtils.isNotEmpty(this.selectedRows);
  }

  get editMode() {
    return this.$store.state.events.editMode;
  }

  set editMode(value: boolean) {
    this.$store.commit('events/setEditMode', value);
  }

  get filterSelectionsShowEventTypes() {
    return this.$store.state.events.filterSelections.showEventTypes;
  }

  set filterSelectionsShowEventTypes(v: Array<any>) {
    this.$store.commit('events/setFilterSelectionsShowEventTypes', v);
  }

  get filterSelections() {
    return this.$store.state.events.filterSelections;
  }

  set filterSelections(v: any) {
    this.$store.commit('events/setFilterSelections', v);
  }

  get selectedEventType() {
    return this.$store.state.events.selectedEventType || '';
  }

  set selectedEventType(value: string) {
    this.$store.commit('events/setSelectedEventType', value);
  }

  created() {
    this.editMode = false;
    this.searchText = '';
    this.showImportEvents = false;
    this.showExportEvents = false;
    this.selectedRows = [];
    this.selectedEventType = '';
  }
}
