var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    { ref: "eventsRef", staticClass: "events", attrs: { color: "#ffffff" } },
    [
      _vm.$currentUser.isDistrictAdmin &&
      (_vm.$vuetify.breakpoint.smAndDown || _vm.isMenuOverlapping())
        ? _c(
            "v-sheet",
            { staticClass: "mx-auto", attrs: { elevation: "0" } },
            [
              _c(
                "v-slide-group",
                {
                  staticClass: "py-2",
                  attrs: { "center-active": "" },
                  model: {
                    value: _vm.localSlideGroupModel,
                    callback: function($$v) {
                      _vm.localSlideGroupModel = $$v
                    },
                    expression: "localSlideGroupModel"
                  }
                },
                [
                  _c(
                    "v-slide-item",
                    { key: 0 },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            transition: "scroll-y-transition",
                            "offset-y": "",
                            "max-height": "500"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              color: "primary",
                                              outlined: "",
                                              rounded: "",
                                              small: ""
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.currentSchool.text))
                                        ]),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-2",
                                            attrs: { small: "" }
                                          },
                                          [_vm._v("fal fa-chevron-down")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1780960934
                          )
                        },
                        [
                          _c(
                            "v-list",
                            _vm._l(_vm.schoolItems, function(school) {
                              return _c(
                                "v-list-item",
                                {
                                  key: school.value,
                                  class: {
                                    "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                      _vm.currentSchoolId == school.value
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.currentSchoolId = school.value
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(school.text) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-0",
                  class: { "mt-5": _vm.$vuetify.breakpoint.mdAndUp },
                  attrs: { flat: "" }
                },
                [
                  _c(
                    "div",
                    { ref: "scrollContainer", staticClass: "scroll-container" },
                    [
                      _c("v-data-table", {
                        ref: "table",
                        attrs: {
                          "item-class": _vm.getItemClass,
                          page: _vm.page,
                          "items-per-page": _vm.itemsPerPage,
                          "footer-props": _vm.footerProps,
                          "multi-sort": false,
                          "show-select": _vm.editMode,
                          loading: _vm.localListLoading,
                          "loading-text": _vm.$t("listLoadingMsg"),
                          headers: _vm.tableHeaders,
                          items: _vm.infiniteScrollList,
                          "selectable-key": "editable",
                          "item-key": "key",
                          height: _vm.tableHeight,
                          "fixed-header": "",
                          "hide-default-footer": "",
                          "disable-pagination": "",
                          "mobile-breakpoint": _vm.mobileBreakpoint,
                          "sort-by": _vm.sortBy,
                          "sort-desc": _vm.sortDesc,
                          "must-sort": true,
                          search: _vm.searchText
                        },
                        on: {
                          "update:page": function($event) {
                            _vm.page = $event
                          },
                          "update:itemsPerPage": function($event) {
                            _vm.itemsPerPage = $event
                          },
                          "update:items-per-page": function($event) {
                            _vm.itemsPerPage = $event
                          },
                          "update:sortBy": function($event) {
                            _vm.sortBy = $event
                          },
                          "update:sort-by": function($event) {
                            _vm.sortBy = $event
                          },
                          "update:sortDesc": function($event) {
                            _vm.sortDesc = $event
                          },
                          "update:sort-desc": function($event) {
                            _vm.sortDesc = $event
                          },
                          "update:search": function($event) {
                            _vm.searchText = $event
                          },
                          "current-items": _vm.setFilteredTableItems
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "body",
                            fn: function(ref) {
                              var items = ref.items
                              var isSelected = ref.isSelected
                              var select = ref.select
                              return [
                                _c("div", {
                                  ref: "topSentinel",
                                  staticClass: "sentinel"
                                }),
                                _c(
                                  "tbody",
                                  {
                                    ref: "listContainer",
                                    staticClass: "list-container"
                                  },
                                  [
                                    _vm._l(items, function(item, index) {
                                      return _c(
                                        "tr",
                                        {
                                          key: index,
                                          staticClass: "table-row",
                                          class:
                                            _vm.getItemClass(item) +
                                            " " +
                                            _vm.mobileTableClass,
                                          attrs: { tabindex: "0", role: "row" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.editEvent(item)
                                            },
                                            keyup: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.editEvent(item)
                                            }
                                          }
                                        },
                                        [
                                          _vm.editMode
                                            ? _c(
                                                "td",
                                                {
                                                  class: _vm.mobileRowClass,
                                                  on: {
                                                    keyup: function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      select(
                                                        item,
                                                        !isSelected(item)
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm.isMobileMode
                                                    ? _c("div", {
                                                        class:
                                                          _vm.mobileHeaderClass
                                                      })
                                                    : _vm._e(),
                                                  _c("v-checkbox", {
                                                    class: _vm.mobileCellClass,
                                                    staticStyle: {
                                                      margin: "0px",
                                                      padding: "0px"
                                                    },
                                                    attrs: {
                                                      disabled: !item.editable,
                                                      "input-value": isSelected(
                                                        item
                                                      ),
                                                      color: !_vm.$vuetify.theme
                                                        .dark
                                                        ? "#7a7a7a"
                                                        : "#ffffff",
                                                      "hide-details": "",
                                                      "aria-label":
                                                        item.eventTitle
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        select(
                                                          item,
                                                          !isSelected(item)
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "td",
                                            { class: _vm.mobileRowClass },
                                            [
                                              _vm.isMobileMode
                                                ? _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.mobileHeaderClass
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.tableHeaders[0]
                                                            .text
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { class: _vm.mobileCellClass },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.currentDateDisplay
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { class: _vm.mobileRowClass },
                                            [
                                              _vm.isMobileMode
                                                ? _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.mobileHeaderClass
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.tableHeaders[1]
                                                            .text
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { class: _vm.mobileCellClass },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.getType(item))
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              class:
                                                _vm.mobileRowClass +
                                                (!item.editable
                                                  ? " event-readonly"
                                                  : "")
                                            },
                                            [
                                              _vm.isMobileMode
                                                ? _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.mobileHeaderClass
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.tableHeaders[2]
                                                            .text
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item.editEventTitle
                                                ? _c(
                                                    "div",
                                                    {
                                                      class: _vm.mobileCellClass
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          autofocus: "",
                                                          dense: "",
                                                          "hide-details": "",
                                                          "append-icon":
                                                            "fa fa-check",
                                                          value:
                                                            item.eventTitle,
                                                          disabled:
                                                            _vm.localListLoading
                                                        },
                                                        on: {
                                                          keyup: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            ) {
                                                              return null
                                                            }
                                                            return _vm.saveEventTitle(
                                                              item
                                                            )
                                                          },
                                                          input: function(
                                                            $event
                                                          ) {
                                                            return _vm.updateEventTitle(
                                                              item,
                                                              $event
                                                            )
                                                          },
                                                          "click:append": function(
                                                            $event
                                                          ) {
                                                            return _vm.saveEventTitle(
                                                              item
                                                            )
                                                          },
                                                          blur:
                                                            _vm.disableEditable
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      class:
                                                        _vm.mobileCellClass,
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          $event.preventDefault()
                                                          return _vm.toggleEventTitleEditable(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-inline-block pr-2"
                                                        },
                                                        [
                                                          _vm.isTrue(
                                                            item.noSchool
                                                          ) ||
                                                          _vm.isTrue(
                                                            item.noCycle
                                                          )
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      item.color
                                                                  },
                                                                  attrs: {
                                                                    small: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fa-solid fa-calendar-xmark"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !_vm.isTrue(
                                                            item.noSchool
                                                          ) &&
                                                          !_vm.isTrue(
                                                            item.noCycle
                                                          ) &&
                                                          !_vm.hasText(
                                                            item.googleId
                                                          )
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      item.color
                                                                  },
                                                                  attrs: {
                                                                    small: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fa-solid fa-calendar-day"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.hasText(
                                                            item.googleId
                                                          )
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "#4285F4"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fab fa-google"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !_vm.hasText(
                                                            item.googleId
                                                          ) && !item.editable
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "ml-2",
                                                                  attrs: {
                                                                    small: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fa fa-lock"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.eventTitle
                                                          )
                                                        )
                                                      ]),
                                                      item.editable
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "ml-2 hover-edit-icon",
                                                              staticStyle: {
                                                                cursor:
                                                                  "pointer"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    "x-small":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fal fa-pencil"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { class: _vm.mobileRowClass },
                                            [
                                              _vm.isMobileMode
                                                ? _c("div", {
                                                    class: _vm.mobileHeaderClass
                                                  })
                                                : _vm._e(),
                                              item.editable
                                                ? _c(
                                                    "div",
                                                    {
                                                      class: _vm.mobileCellClass
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "mx-3 hover-button-primary",
                                                          attrs: {
                                                            text: "",
                                                            small: "",
                                                            color:
                                                              _vm.contentBreakpoint ===
                                                              "xs"
                                                                ? "primary"
                                                                : "transparent"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.editEvent(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "editLabel"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "mx-3 hover-button-error",
                                                          attrs: {
                                                            text: "",
                                                            small: "",
                                                            color:
                                                              _vm.contentBreakpoint ===
                                                              "xs"
                                                                ? "error"
                                                                : "transparent"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.deleteEvent(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "deleteLabel"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !item.editable
                                                ? _c(
                                                    "div",
                                                    {
                                                      class: _vm.mobileCellClass
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "mx-3 hover-button-primary",
                                                          attrs: {
                                                            text: "",
                                                            small: "",
                                                            color:
                                                              _vm.contentBreakpoint ===
                                                              "xs"
                                                                ? "primary"
                                                                : "transparent"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.editEvent(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "viewLabel"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    _vm.canLoadMore &&
                                    items.length !==
                                      _vm.infiniteScrollList.length &&
                                    (_vm.searchText === null ||
                                      _vm.searchText === "")
                                      ? _c("tr", [
                                          _c("td"),
                                          _c("td"),
                                          _c("td", [
                                            _c(
                                              "div",
                                              {
                                                attrs: {
                                                  align: "center",
                                                  justify: "center"
                                                }
                                              },
                                              [
                                                _vm.isLoadingMore ||
                                                _vm.initialLoad
                                                  ? _c("v-progress-circular", {
                                                      attrs: {
                                                        color: "primary",
                                                        indeterminate: ""
                                                      }
                                                    })
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "12px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "scrollDownToLoadMoreLabel"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { small: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fal fa-arrow-down"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                              ],
                                              1
                                            )
                                          ]),
                                          _c("td")
                                        ])
                                      : _vm._e()
                                  ],
                                  2
                                ),
                                _c("div", {
                                  ref: "sentinel",
                                  staticClass: "sentinel",
                                  style: {
                                    "margin-bottom":
                                      _vm.canLoadMore &&
                                      !_vm.isLoadingMore &&
                                      _vm.localEvents.length !==
                                        _vm.infiniteScrollList.length
                                        ? "70px"
                                        : ""
                                  }
                                })
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.selectedRows,
                          callback: function($$v) {
                            _vm.selectedRows = $$v
                          },
                          expression: "selectedRows"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" }),
      _c(
        "pb-base-modal",
        {
          attrs: {
            maxWidth: 500,
            expandable: false,
            withActions: false,
            withApply: false
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [_vm._v(_vm._s(_vm.$t("importEventsLabel")))]
              },
              proxy: true
            },
            {
              key: "action-button",
              fn: function() {
                return [
                  _vm.$currentUser.isDistrictAdmin
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            "offset-y": "",
                            "close-on-content-click": ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "pb-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              label: _vm.$t("applyLabel"),
                                              icon: "",
                                              right: "",
                                              disabled: _vm.listLoading
                                            }
                                          },
                                          "pb-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm.listLoading
                                          ? _c("v-icon", [
                                              _vm._v("fal fa-sync fa-spin")
                                            ])
                                          : _c("v-icon", [
                                              _vm._v("fal fa-check")
                                            ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            222031279
                          )
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.importEvents(false)
                                    }
                                  }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.$t("saveToSchoolLabel")))
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.importEvents(true)
                                    }
                                  }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      _vm._s(_vm.$t("saveToDistrictLabel"))
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "pb-btn",
                        {
                          attrs: {
                            label: _vm.$t("saveLabel"),
                            icon: "",
                            right: "",
                            disabled: _vm.listLoading
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.importEvents(false)
                            }
                          }
                        },
                        [
                          _vm.listLoading
                            ? _c("v-icon", [_vm._v("fal fa-sync fa-spin")])
                            : _c("v-icon", [_vm._v("fal fa-check")])
                        ],
                        1
                      )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showImportEvents,
            callback: function($$v) {
              _vm.showImportEvents = $$v
            },
            expression: "showImportEvents"
          }
        },
        [
          _vm.importEventsLoading
            ? _c("v-progress-linear", {
                staticStyle: { "margin-top": "-10px", "margin-bottom": "6px" },
                attrs: { indeterminate: "", color: "blue darken-1" }
              })
            : _vm._e(),
          _c(
            "v-form",
            { ref: "importForm" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("importFromLabel")))
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            _c(
                              "v-btn-toggle",
                              {
                                attrs: {
                                  dense: "",
                                  mandatory: "",
                                  color: "primary"
                                },
                                model: {
                                  value: _vm.importType,
                                  callback: function($$v) {
                                    _vm.importType = $$v
                                  },
                                  expression: "importType"
                                }
                              },
                              [
                                _vm.$currentUser.isTeacher
                                  ? _c(
                                      "v-btn",
                                      { attrs: { value: "T", width: "150px" } },
                                      [_vm._v(_vm._s(_vm.$t("teacherLabel")))]
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-btn",
                                  { attrs: { value: "C", width: "150px" } },
                                  [_vm._v(_vm._s(_vm.$t("csvFileLabel")))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.importType === "T"
                    ? _c(
                        "v-row",
                        { staticClass: "pb-5" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  "hide-details": "auto",
                                  label: _vm.$t("teacherEmailLabel") + "*",
                                  rules: _vm.emailRules,
                                  "aria-required": "true"
                                },
                                on: {
                                  input: function($event) {
                                    _vm.importEventsTyping = true
                                  }
                                },
                                model: {
                                  value: _vm.importTeacherEmail,
                                  callback: function($$v) {
                                    _vm.importTeacherEmail = $$v
                                  },
                                  expression: "importTeacherEmail"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  "hide-details": "auto",
                                  label: _vm.$t("teacherKeyLabel") + "*",
                                  rules: _vm.localRules,
                                  "aria-required": "true"
                                },
                                on: {
                                  input: function($event) {
                                    _vm.importEventsTyping = true
                                  }
                                },
                                model: {
                                  value: _vm.importTeacherKey,
                                  callback: function($$v) {
                                    _vm.importTeacherKey = $$v
                                  },
                                  expression: "importTeacherKey"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  "hide-details": "auto",
                                  label: _vm.$t("schoolYearToImport") + "*",
                                  items: _vm.importSchoolYearItems,
                                  rules: _vm.localRules,
                                  "aria-required": "true"
                                },
                                model: {
                                  value: _vm.importTeacherSchoolYearId,
                                  callback: function($$v) {
                                    _vm.importTeacherSchoolYearId = $$v
                                  },
                                  expression: "importTeacherSchoolYearId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        { staticClass: "pb-5" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("importEventsCSVMsg1")))
                              ]),
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("importEventsCSVMsg2")
                                  )
                                }
                              }),
                              _c("v-file-input", {
                                attrs: {
                                  clearable: "",
                                  loading: _vm.selectingCSVFile,
                                  outlined: "",
                                  "hide-details": "auto",
                                  "prepend-inner-icon": "mdi-paperclip",
                                  "prepend-icon": "",
                                  accept: ".csv",
                                  label: _vm.$t("csvFileLabel") + " (*.csv)",
                                  placeholder:
                                    _vm.$t("csvFileLabel") + " (*.csv)",
                                  rules: _vm.localRules,
                                  "aria-required": "true"
                                },
                                model: {
                                  value: _vm.importCSVFile,
                                  callback: function($$v) {
                                    _vm.importCSVFile = $$v
                                  },
                                  expression: "importCSVFile"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "pb-base-modal",
        {
          attrs: {
            maxWidth: 500,
            expandable: false,
            withActions: false,
            withApply: false
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c("div", { staticClass: "pr-10" }, [
                    _vm._v(_vm._s(_vm.$t("exportEventsLabel")))
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "bottom-actions",
              fn: function() {
                return [
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2 mb-2",
                          attrs: { color: "error" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.exportEvents(false)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("includeHTMLLabel")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-1 mb-2",
                          attrs: { color: "error" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.exportEvents(true)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("excludeHTMLLabel")))]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showExportEvents,
            callback: function($$v) {
              _vm.showExportEvents = $$v
            },
            expression: "showExportEvents"
          }
        },
        [
          _c(
            "v-container",
            [
              _vm.$currentUser.isDistrictAdmin
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0" },
                        [
                          _c("v-select", {
                            attrs: {
                              outlined: "",
                              "hide-details": "auto",
                              label: _vm.$t("schoolLabel"),
                              items: _vm.schoolDistrictItems
                            },
                            model: {
                              value: _vm.exportSchoolId,
                              callback: function($$v) {
                                _vm.exportSchoolId = $$v
                              },
                              expression: "exportSchoolId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$currentUser.isDistrictAdmin && _vm.exportSchoolId !== 0
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0" },
                        [
                          _c("v-checkbox", {
                            attrs: { label: _vm.$t("excludeDistrictEvents") },
                            model: {
                              value: _vm.excludeDistrictEvents,
                              callback: function($$v) {
                                _vm.excludeDistrictEvents = $$v
                              },
                              expression: "excludeDistrictEvents"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.$t("exportEventsMsg")) }
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { attrs: { id: "dynamicStyle" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }