



















































































































































































































































import { emailFormat, tableWidths, infiniteScrollDelay, contrastModeColors } from '@/constants';
import { FormError } from '@/errors';
import EventServices from '@/services/event-services';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import ld from 'lodash';
import moment from 'moment-timezone';
import { Debounce } from 'vue-debounce-decorator';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '../../components/core/Confirm.vue';
import EventsMixin from '@/mixins/events-mixin';
import TableResizeMixin from '@/mixins/table-resize-mixin';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import CurrentUser from '@/common/current-user';

const settings = namespace('settings');
const events = namespace('events');
const google = namespace('google');

@Component({
  mixins: [PageLifeCycleMixin, EventsMixin, TableResizeMixin]
})
export default class Events extends Vue {
  @settings.Getter('getSettings')
  userInfo!: any;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  @settings.Getter('getSchools')
  schools!: any;

  @settings.Getter('getPrimarySchool')
  primarySchool!: any;

  @settings.Getter('getSchoolId')
  schoolId!: number;

  @settings.Getter('getDistrictId')
  userDistrictId!: number;

  @events.Action
  loadEvents!: (params?: any) => Promise<any>;

  @events.Action
  init!: (params?: any) => Promise<any>;

  @events.Action
  delete!: (params?: any) => Promise<any>;

  @events.Action('deleteEvents')
  doDeleteEvents!: (params?: any) => Promise<any>;

  @settings.Getter('getEventStyling')
  eventStyling!: any;

  @settings.Getter('getDateStyling')
  dateStyling!: any;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @settings.Getter('darkMode')
  darkMode!: boolean;

  @settings.Getter('getBrowserWidth')
  browserWidth!: number;

  @events.State
  eventId!: number;

  @events.State
  events!: Array<any>;

  @events.State
  noSchool!: string;

  @events.State
  noCycle!: string;

  @events.State
  origEvent!: any;

  @events.Action('save')
  save!: (params?: any) => Promise<any>;

  @events.Action('export')
  doExport!: (params: any) => Promise<any>;

  @events.Action('import')
  doImport!: (params: any) => Promise<any>;

  @events.State
  eventDate!: string;

  @events.State
  endDate!: string;

  @events.State
  extraDays!: Array<any>;

  @events.State
  eventCurrentDate!: string;

  @events.Getter('getLimit')
  limit!: number;

  @events.Getter('getListLoading')
  listLoading!: boolean;

  @events.Getter('getDoneFetchingEvents')
  doneFetchingEvents!: boolean;

  @events.Mutation
  setDoneFetchingEvents!: (val: boolean) => void;

  @events.Mutation
  spliceEvents!: (params: any) => void;

  @google.Getter
  getIsConnectedToGoogle!: boolean;

  localEvents: Array<any> = [];
  origEvents: Array<any> = [];
  importTeacherEmail = '';
  importTeacherKey = '';
  importTeacherSchoolYearId = 0;
  importTeacherId = 0;
  importCSVFile: any = null;
  importType = 'C';
  selectingCSVFile = false;
  importSchoolYearItems = [];
  importEventsLoading = false;
  importEventsTyping = false;
  tableHeight!: any;
  firstLoad = true;
  sortBy = 'currentEventSortDate';
  sortDesc = false;
  canHandleStartIntersection = false;
  shouldSearchBack = false;
  isSearchingBack = false;

  localIsMenuOverlapping = false;
  lastBrowserWidth = 0;
  localSlideGroupModel = null;

  editMode!: boolean;
  searchText!: string;
  selectedRows!: Array<any>;
  showImportEvents!: boolean;
  showExportEvents!: boolean;
  hasSelectedRows!: boolean;
  selectedEventType!: string;
  exportSchoolId = 0;
  excludeDistrictEvents = false;

  filterSelectionsShowEventTypes!: Array<any>;
  filterSelections!: any;

  infiniteScrollList:any[] = [];
  filteredTableItems:any[] = [];
  isLoadingMore = false;
  canLoadMore = true;
  initialLoad = true;
  pageNumber = 0;
  pageCount = 0;
  listEndObserver:any = undefined;
  listStartObserver:any = undefined;

  page = 1;
  itemsPerPage = 10;

  $refs!: {
    confirm: Confirm,
    importForm: Vue & { validate: () => boolean, resetValidation: () => void },
    table: any,
    sentinel: any,
    topSentinel: any,
    scrollContainer:any
  }

  get currentSchool() {
    return this.schoolItems.find((s: any) => s.value === this.currentSchoolId) || {};
  }

  get currentSchoolId() {
    return this.$store.state.events.currentSchoolId || this.primarySchool.schoolId;
  }

  set currentSchoolId(val:any) {
    this.$store.commit('events/setCurrentSchoolId', val);
  }

  get localRules() {
    return [
      (v: any) => !!v || this.$t('requiredLabel')
    ];
  }

  get emailRules() {
    return this.localRules.concat([
      (v: string) => emailFormat.test(v) || this.$t('emailMustBeValidMsg')
    ]);
  }

  get footerProps() {
    return {
      'items-per-page-options': [10, 25, 50, 100, -1]
    }
  }

  get itemDataSelect() {
    return 'item.data-table-select'
  }

  get tableHeaders() {
    return [
      {
        text: this.$t('dateLabel'),
        value: 'currentEventSortDate',
        width: tableWidths.fullDate
      },
      {
        text: this.$t('typeLabel'),
        value: 'type',
        width: tableWidths.shortText
      },
      {
        text: this.$t('eventLabel'),
        align: 'start',
        value: 'eventTitle'
      },
      {
        width: tableWidths.action2,
        sortable: false,
        value: 'action'
      }
    ];
  }

  get haveEvents() {
    return this.$store.state.events.haveEvents;
  }

  set haveEvents(value: boolean) {
    this.$store.commit('events/setHaveEvents', value);
  }

  get shiftLessons() {
    return this.$store.state.events.shiftLessons;
  }

  set shiftLessons(value: boolean) {
    this.$store.commit('events/setShiftLessons', value);
  }

  get updateCurrentEvent() {
    return this.$store.state.events.updateCurrentEvent;
  }

  set updateCurrentEvent(value: boolean) {
    this.$store.commit('events/setUpdateCurrentEvent', value);
  }

  get isCycleSchedule() {
    return !isNaN(+this.userInfo.schoolYearSettings.classCycle);
  }

  get formatToShow() {
    return DateTimeUtils.formatToShow
  }

  get stringifyDate() {
    return DateTimeUtils.stringifyDate;
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get isTrue() {
    return CommonUtils.isTrue;
  }

  get getColor() {
    return CommonUtils.getColor;
  }

  get customEventId(): number {
    return +this.$store.state.events.customEventId;
  }

  get shouldUpdateCurrentEvent() {
    return this.eventDate !== this.endDate || CommonUtils.isNotEmpty(this.extraDays);
  }

  get localListLoading() {
    return this.listLoading;
  }

  set localListLoading(value: boolean) {
    this.$store.commit('events/setListLoading', value);
  }

  get localEventTypeItems() {
    var eventTypeItems = [];
    if (CurrentUser.isTeacher) {
      eventTypeItems.push({ value: 'Teacher', text: this.$t('teacherLabel') });
    }
    if (CommonUtils.hasValue(this.schoolId)) {
      eventTypeItems.push({ value: 'School', text: this.$t('schoolLabel') });
    }
    if (CommonUtils.hasValue(this.userDistrictId)) {
      eventTypeItems.push({ value: 'District', text: this.$t('districtLabel') });
    }
    if (this.getIsConnectedToGoogle) {
      eventTypeItems.push({ value: 'Google', text: this.$t('googleLabel') });
    }
    return eventTypeItems;
  }

  get mobileBreakpoint() {
    return CommonUtils.getMobileBreakpointSize();
  }

  get isMobileMode() {
    return CommonUtils.isMobileMode();
  }

  get mobileTableClass() {
    return CommonUtils.mobileTableClass();
  }

  get mobileRowClass() {
    return CommonUtils.mobileRowClass();
  }

  get mobileHeaderClass() {
    return CommonUtils.mobileHeaderClass();
  }

  get mobileCellClass() {
    return CommonUtils.mobileCellClass();
  }

  get contentBreakpoint() {
    return CommonUtils.mainContentBreakpoint();
  }

  get schoolDistrictItems() {
    return [{ text: this.$t('exportDistrictEvents'), value: 0 }].concat(this.schoolItems)
  }

  get schoolItems() {
    return this.schools.map((s: any) => {
      return { text: s.schoolName, value: +s.schoolId };
    });
  }

  @Watch('currentSchoolId')
  onCurrentSchoolIdChanged() {
    this.initialize();
  }

  @Watch('filterSelections.showEventTypes')
  applyFilters() {
    if (this.filterSelectionsShowEventTypes.length === this.localEventTypeItems.length) {
      this.selectedEventType = 'all';
    } else if (this.filterSelectionsShowEventTypes.length === 0) {
      this.selectedEventType = 'none';
    } else {
      this.selectedEventType = '';
    }
    this.initializeInfiniteScroll();
  }

  @Watch('importTeacherEmail')
  @Watch('importTeacherKey')
  @Debounce(500)
  onImportFieldChange() {
    this.importEventsTyping = false;
  }

  @Watch('importEventsTyping')
  onTypingChange() {
    if (!this.importEventsTyping) {
      this.fetchSharedSchoolYears();
    }
  }

  @Watch('browserWidth')
  onBrowserWidthChanged(val:any) {
    this.isMenuOverlapping(val);
  }

  isMenuOverlapping(browserWidth?:number) {
    if (this.lastBrowserWidth !== 0 && (browserWidth || 0) < this.lastBrowserWidth) {
      this.localIsMenuOverlapping = true;
      return true;
    }
    if ((browserWidth || 0) > this.lastBrowserWidth) {
      this.localIsMenuOverlapping = false;
      this.lastBrowserWidth = 0;
      return false;
    }
    setTimeout(() => {
      const toolbarTitle:any = document.getElementById('toolbarTitle');
      const titleRect = toolbarTitle ? toolbarTitle.getBoundingClientRect() : null;
      const eventsTodayButton:any = document.getElementById('eventsTodayButton');
      if (eventsTodayButton && titleRect) {
        const eventsTodayButtonRect = eventsTodayButton.getBoundingClientRect();
        if (
          titleRect.top < eventsTodayButtonRect.bottom &&
            titleRect.bottom > eventsTodayButtonRect.top &&
            titleRect.left < eventsTodayButtonRect.right &&
            titleRect.right > eventsTodayButtonRect.left
        ) {
          this.lastBrowserWidth = this.browserWidth;
          this.localIsMenuOverlapping = true;
          return true;
        }
        this.localIsMenuOverlapping = false;
        return false;
      } else {
        this.localIsMenuOverlapping = false;
        return false;
      }
    }, 50);
    this.localIsMenuOverlapping = false;
    return false;
  }

  fetchSharedSchoolYears() {
    if (CommonUtils.hasText(this.importTeacherEmail) && CommonUtils.hasText(this.importTeacherKey)) {
      this.importEventsLoading = true;
      EventServices.getSharedTerms({
        sharedUserEmail: this.importTeacherEmail,
        sharedUserKey: this.importTeacherKey
      }).then(resp => {
        if (resp.data) {
          this.importTeacherId = resp.data.sharedUserId;
          if (!this.importTeacherId) this.importTeacherSchoolYearId = 0;
          this.importSchoolYearItems = resp.data.years.map((y: any) => {
            if (this.importTeacherSchoolYearId === 0) {
              this.importTeacherSchoolYearId = y.yearId;
            }
            return {
              value: y.yearId,
              text: y.yearName
            }
          });
        }
      }).finally(() => {
        this.importEventsLoading = false;
      })
    }
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode === false) {
      this.selectedRows = [];
    }
  }

  importEvents(isDistrictEvent = false) {
    if (this.$refs.importForm.validate()) {
      this.$store.commit('events/setIsDistrictEvent', isDistrictEvent);
      CommonUtils.showLoading();
      this.doImport({
        type: this.importType,
        file: this.importCSVFile,
        sharedTeacherId: this.importTeacherId,
        sharedYearId: this.importTeacherSchoolYearId
      }).then(() => {
        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg11') as string);
        }
        this.importTeacherEmail = '';
        this.importTeacherKey = '';
        this.importTeacherSchoolYearId = 0;
        this.importCSVFile = null;
        return Promise.resolve();
      }).finally(() => {
        this.showImportEvents = false;
        this.$refs.importForm.resetValidation();
        this.$eventBus.$emit('closeSubPage');
        CommonUtils.hideLoading();
      })
    }
  }

  @Watch('showExportEvents')
  openExport() {
    if (this.showExportEvents) {
      this.exportSchoolId = this.currentSchoolId;
      this.excludeDistrictEvents = false;
    }
  }

  exportEvents(removeHTML: boolean) {
    CommonUtils.showLoading();
    this.doExport({ schoolId: this.exportSchoolId, excludeDistrictEvents: this.excludeDistrictEvents, removeHTML: removeHTML }).then(() => {
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('statusMsg13') as string);
      }
      return Promise.resolve();
    }).finally(() => {
      this.showExportEvents = false;
      CommonUtils.hideLoading();
    })
  }

  isEditable(event: any) {
    if (CommonUtils.hasText(event.googleId)) {
      return false;
    } else if (event.schoolId && event.schoolId > 0 && !this.$currentUser.isAdmin) {
      return false;
    } else if (event.districtId && event.districtId > 0 && !this.$currentUser.isDistrictAdmin) {
      return false;
    }
    return true;
  }

  getType(event: any) {
    if (CommonUtils.hasText(event.googleId)) {
      return this.$t('googleLabel');
    } else if (event.districtId && event.districtId > 0) {
      return this.$t('districtLabel');
    } else if (event.schoolId && event.schoolId > 0) {
      return this.$t('schoolLabel');
    }
    return this.$t('teacherLabel');
  }

  getTypeValue(event: any) {
    if (CommonUtils.hasText(event.googleId)) {
      return 'Google';
    } else if (event.districtId && event.districtId > 0) {
      return 'District';
    } else if (event.schoolId && event.schoolId > 0) {
      return 'School';
    }
    return 'Teacher';
  }

  toggleEventTitleEditable(item: any) {
    const events = ld.cloneDeep(this.infiniteScrollList);
    events.forEach(e => {
      e.editEventTitle = false;
    });
    if (item.editable) {
      const event = events.find(e => e.key === item.key);
      event.editEventTitle = !event.editEventTitle;
    }
    this.infiniteScrollList = events;
  }

  @Watch('showImportEvents')
  onShowImportEventsChange() {
    if (this.showImportEvents) {
      if (this.$refs.importForm) {
        this.$refs.importForm.resetValidation();
      }
      this.importTeacherEmail = '';
      this.importTeacherKey = '';
      this.importTeacherSchoolYearId = 0;
      this.importCSVFile = null;
    }
  }

  @Watch('localListLoading')
  disableEditable() {
    if (!this.localListLoading) {
      const events = ld.cloneDeep(this.infiniteScrollList);
      events.forEach(e => {
        e.editEventTitle = false;
      });
      this.infiniteScrollList = events;
    }
  }

  updateEventTitle(item: any, value: string) {
    const events = ld.cloneDeep(this.infiniteScrollList);
    const event = events.find(e => e.key === item.key);
    event.eventTitle = value;
    this.infiniteScrollList = events;
  }

  saveEventTitle(item: any) {
    const events = ld.cloneDeep(this.infiniteScrollList);
    const event = events.find(e => e.key === item.key);
    const origEvent = this.origEvents.find(e => e.key === item.key);
    this.localListLoading = true;
    if (item.eventTitle !== origEvent.eventTitle) {
      event.eventTitle = item.eventTitle;
      events.forEach(e => {
        e.editEventTitle = false;
      });
      this.init({
        loadData: true,
        data: event
      }).then(() => {
        if (this.shouldUpdateCurrentEvent) {
          return this.$refs.confirm.confirm({
            title: this.$t('editEventLabel'),
            text: this.$t('editEventMsg2', { text: DateTimeUtils.formatToShow(this.eventCurrentDate) }),
            option1ButtonAlternativeText: this.$t('allEventsLabel'),
            option2ButtonAlternativeText: this.$t('onlyThisEventLabel')
          }).then((result) => {
            if (result === 1) {
              return Promise.resolve();
            } else if (result === 2) {
              this.updateCurrentEvent = true;
              return Promise.resolve();
            } else {
              event.eventTitle = origEvent.eventTitle;
              this.infiniteScrollList = events;
              return Promise.reject(new FormError());
            }
          });
        }
      }).then(async () => {
        return this.save().then((resp) => {
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg15') as string);
          }
          this.initialize();
          return Promise.resolve(resp);
        });
      }).catch(error => {
        if (!(error instanceof FormError)) {
          Promise.reject(error);
        }
      }).finally(() => {
        this.localListLoading = false;
      });
    } else {
      this.$nextTick(() => {
        this.localListLoading = false;
      })
    }
  }

  compareInstances(start: string, end: string) {
    const momentStart = moment(start, 'MM/DD/YYYY', true);
    const momentEnd = moment(end, 'MM/DD/YYYY', true);
    if (momentStart > momentEnd) return 1;
    else if (momentStart < momentEnd) return -1;
    else return 0;
  }

  editEvent(item: any) {
    this.$eventBus.$emit('openSubPage', {
      type: 'event',
      modal: this.$currentUser.defaultEditorMode === 'modal',
      width: 600,
      input: {
        loadData: true,
        data: item
      }
    });
  }

  async deleteEvents() {
    if (this.hasSelectedRows) {
      return this.$refs.confirm.confirm({
        title: this.$t('deleteEventsLabel'),
        text: this.$t('confirmDeleteEventsMesage'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then((result) => {
        if (result === 1) {
          return Promise.resolve();
        } else {
          return Promise.reject(new FormError());
        }
      }).then(async () => {
        const payload: any = { shiftLessons: false };
        if (this.$currentUser.isTeacher) {
          if (CommonUtils.isTrue(this.selectedHasNoSchool)) {
            if (this.isCycleSchedule) {
              payload.shiftLessons = true;
              return Promise.resolve(payload);
            } else {
              return this.$refs.confirm.confirm({
                title: this.$t('noSchoolEventsLabel'),
                text: this.$t('shiftLessonsMsg5'),
                option1ButtonAlternativeText: this.$t('shiftLessonsLabel'),
                option2ButtonAlternativeText: this.$t('doNotShiftLessonsLabel')
              }).then((result) => {
                if (result === 1) {
                  payload.shiftLessons = true;
                  return Promise.resolve(payload);
                } else if (result === 2) {
                  payload.shiftLessons = false;
                  return Promise.resolve(payload);
                } else {
                  return Promise.reject(new FormError());
                }
              });
            }
          } else if (CommonUtils.isTrue(this.selectedHasNoCycle)) {
            payload.shiftLessons = true;
            return Promise.resolve(payload);
          }
        }
        return Promise.resolve(payload);
      }).then((payload) => {
        this.localListLoading = true;
        payload.events = this.selectedRows;
        const deletedIds = this.selectedRows.map((e: any) => { return e.eventId });
        const deletedKeys = this.selectedRows.map((e: any) => { return e.key });
        return this.doDeleteEvents(payload).then(() => {
          this.$eventBus.$emit('deleteEvent', deletedIds);
          this.infiniteScrollList = this.infiniteScrollList.filter(e => !deletedKeys.includes(e.key));
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('eventsDeletedMsg') as string);
          }
          return Promise.resolve();
        });
      }).catch(error => {
        if (!(error instanceof FormError)) {
          Promise.reject(error);
        }
      }).finally(() => {
        this.selectedRows = [];
        this.localListLoading = false;
      });
    }
  }

  get selectedHasNoSchool() {
    return this.selectedRows.some((e: any) => CommonUtils.isTrue(e.noSchool));
  }

  get selectedHasNoCycle() {
    return this.selectedRows.some((e: any) => CommonUtils.isTrue(e.noCycle));
  }

  deleteEvent(event: any) {
    if (!event.editable) {
      return;
    }
    this.localListLoading = true;
    return this.$refs.confirm.confirm({
      title: this.$t('deleteEventLabel'),
      text: this.$t('confirmDeleteEventMessage'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        return Promise.resolve();
      } else {
        return Promise.reject(new FormError());
      }
    }).then(async () => {
      return this.init({ data: event, loadData: true }).then(() => {
        const withCustomEvent = (this.customEventId > 0 || CommonUtils.isNotEmpty(this.origEvent.extraDays) || CommonUtils.isNotEmpty(this.origEvent.noEventDays));
        if (withCustomEvent || this.eventDate !== this.endDate) {
          return this.$refs.confirm.confirm({
            title: this.$t('deleteEventLabel'),
            text: this.$t('wouldYouLikeToDeleteEventMsg', { text: DateTimeUtils.formatToShow(this.eventCurrentDate) }),
            option1ButtonAlternativeText: this.$t('allEventsLabel'),
            option2ButtonAlternativeText: this.$t('onlyThisEventLabel')
          }).then((result) => {
            if (result === 1) {
              return Promise.resolve();
            } else if (result === 2) {
              this.updateCurrentEvent = true;
              return Promise.resolve();
            } else {
              return Promise.reject(new FormError());
            }
          })
        } else {
          Promise.resolve();
        }
      })
    }).then(() => {
      if (this.$currentUser.isTeacher) {
        if (CommonUtils.isTrue(this.noSchool)) {
          return this.confirmShiftLessons(this.$t('shiftLessonsMsg4') as string);
        } else if (CommonUtils.isTrue(this.noCycle)) {
          this.shiftLessons = true;
          return Promise.resolve();
        }
      } else {
        return Promise.resolve();
      }
    }).then(() => {
      this.$eventBus.$emit('deleteEvent', [event.eventId]);
      if (this.updateCurrentEvent) {
        this.infiniteScrollList = this.infiniteScrollList.filter(e => e.key !== event.key);
      } else {
        this.infiniteScrollList = this.infiniteScrollList.filter(e => e.eventId !== event.eventId);
      }
      this.localListLoading = true;
      return this.delete().then(() => {
        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg16') as string);
        }
      });
    }).then(() => {
      return Promise.resolve();
    }).catch(error => {
      if (!(error instanceof FormError)) {
        Promise.reject(error);
      }
    }).finally(() => {
      this.localListLoading = false;
    });
  }

  async confirmShiftLessons(message: string) {
    if (this.isCycleSchedule) {
      this.shiftLessons = true;
      return Promise.resolve();
    } else {
      return this.$refs.confirm.confirm({
        title: this.$t('noSchoolEventLabel'),
        text: message,
        option1ButtonAlternativeText: this.$t('shiftLessonsLabel'),
        option2ButtonAlternativeText: this.$t('doNotShiftLessonsLabel')
      }).then((result) => {
        if (result === 1) {
          this.shiftLessons = true;
          return Promise.resolve();
        } else if (result === 2) {
          this.shiftLessons = false;
          return Promise.resolve();
        } else {
          return Promise.reject(new FormError());
        }
      });
    }
  }

  scrollToToday() {
    this.$nextTick(async () => {
      try {
        const todayElement = this.$refs.table.$el.querySelector('.event-today');
        if (todayElement) {
          todayElement.scrollIntoViewIfNeeded();
        } else {
          const nextElement = this.$refs.table.$el.querySelector('.event-future');
          if (nextElement) {
            this.$refs.table.$el.querySelector('.event-future').scrollIntoViewIfNeeded();
          } else {
            if (!this.initialLoad && this.canLoadMore && !this.shouldSearchBack && this.$refs.table.$el.querySelector('.table-row') !== null) {
              await this.loadMore();
              this.scrollToToday();
            }
          }
        }
      } catch (e) {
      }
    });
  }

  async initialize() {
    if (this.firstLoad) {
      this.filterSelections = {
        showEventTypes: this.localEventTypeItems.map((i: any) => i.value)
      }
      this.currentSchoolId = this.primarySchool.schoolId;
      this.selectedEventType = 'all';
      if (CurrentUser.isTeacher) this.importType = 'T';
    }
    this.haveEvents = false;
    this.localListLoading = false;
    this.initializeInfiniteScroll();
  }

  getItemClass(item: any) {
    let itemClass = 'event';
    if (item.currentDate === moment().format('MM/DD/YYYY')) {
      itemClass += ' event-today';
    } else if (DateTimeUtils.isThisDateAfterThatDate(item.currentDate, moment().format('MM/DD/YYYY'))) {
      itemClass += ' event-future';
    }
    return itemClass;
  }

  scroll() {
    const that = this;

    if (!this.canHandleStartIntersection) {
      setTimeout(() => {
        that.canHandleStartIntersection = true;
      }, 500);
    }
  }

  created() {
    this.$nextTick(() => {
      this.$eventBus.$on('refreshEvents', () => {
        this.initialize();
      });

      this.$eventBus.$on('userModeChanged', this.initialize)
      this.$eventBus.$emit('openDefaultSubPage');

      const dataTableWrapper = document.querySelector('.v-data-table__wrapper');
      if (dataTableWrapper) {
        dataTableWrapper.addEventListener('scroll', this.scroll, { passive: true });
      }
    });
    this.firstLoad = true;
    this.initialize();
  }

  beforeDestroy() {
    this.$eventBus.$off('userModeChanged', this.initialize);
    this.$eventBus.$off('refreshEvents');
  }

  mounted() {
    const styleContainer = document.getElementById('dynamicStyle') as HTMLElement;
    const style = document.createElement('style');
    styleContainer.innerHTML = '';
    style.innerHTML = `
      .event.event-today {
        color: ${this.dateStyling.currentDateTextColor} !important;
        background-color: ${this.dateStyling.currentDateBackgroundColor} !important;
      }
    `;
    styleContainer.appendChild(style);
  }

  insertNewEvents(previousLocalEvents:any[]) {
    const newEvents:any[] = [];
    this.localEvents.forEach((e:any, index:number) => {
      const foundInPrevEvents = previousLocalEvents.some(pE => pE.key === e.key);
      if (!foundInPrevEvents && this.filterSelectionsShowEventTypes.includes(this.getTypeValue(e))) {
        newEvents.push({
          index: index,
          event: e
        });
      }
    });
    for (const newEvent of newEvents) {
      if (this.infiniteScrollList.length >= newEvent.index) {
        this.infiniteScrollList.splice(newEvent.index, 0, newEvent.event);
      } else {
        this.infiniteScrollList.push(newEvent.event);
      }
    }
  }

  updateEventsList(previousLocalEvents:any[]) {
    const updatedEvents:any[] = [];
    this.localEvents.forEach((e:any, index:number) => {
      const prevEvent = previousLocalEvents.find(pE => pE.key === e.key);
      if (!ld.isEqual(e, prevEvent) && this.filterSelectionsShowEventTypes.includes(this.getTypeValue(e))) {
        updatedEvents.push({
          index: index,
          event: e
        });
      }
    });
    for (const updatedEvent of updatedEvents) {
      if (this.infiniteScrollList.length >= updatedEvent.index) {
        Vue.set(this.infiniteScrollList, updatedEvent.index, updatedEvent.event);
      }
    }
  }

  initializeInfiniteScroll() {
    this.resetInfiniteScrollData();

    if (this.initialLoad || CommonUtils.isNotEmpty(this.localEvents)) {
      this.setInfiniteScrollInitialData();
    } else {
      this.initialLoad = false;
      this.canLoadMore = false;
    }
  }

  resetInfiniteScrollData() {
    this.infiniteScrollList = [];
    this.localEvents = [];

    if (this.listEndObserver) {
      this.listEndObserver.disconnect();
    }
    if (this.listStartObserver) {
      this.listStartObserver.disconnect();
    }

    this.isLoadingMore = false;
    this.canLoadMore = true;

    this.initialLoad = true;
    this.pageNumber = 0;
    this.pageCount = 0;
    this.listEndObserver = undefined;
    this.listStartObserver = undefined;
    this.canHandleStartIntersection = false;
    this.shouldSearchBack = false;
    this.isSearchingBack = false;

    this.setDoneFetchingEvents(false);
  }

  async setInfiniteScrollInitialData() {
    const that = this;
    this.fetchMoreItems().then(() => {
      that.initialLoad = false;
      // wait for initial list to render and then set up observer
      that.$nextTick(() => {
        that.scrollToToday();
        that.setUpInterSectionObserver();
      });

      const scrollContainer = that.$refs.scrollContainer;
      const tableRowHeight = 48;
      const itemsPerScreen = (Math.round(scrollContainer.clientHeight / tableRowHeight));
      if (that.filteredTableItems.length < itemsPerScreen) {
        that.shouldSearchBack = true;
        that.loadMore(that.isSearchingBack);
      }
    }).catch((error: any) => {
      console.log('Reached end of page', error);
      console.log('total items in the page ' + that.infiniteScrollList.length);
      that.canLoadMore = false;
    }).finally(() => {
      that.isLoadingMore = false;
    });
  }

  setUpInterSectionObserver() {
    const options: any = {
      root: this.$refs.scrollContainer,
      rootMargin: '0px 0px 0px 0px',
      threshold: 1.0
    };
    this.listEndObserver = new IntersectionObserver(
      this.handleIntersection,
      options
    );
    this.listStartObserver = new IntersectionObserver(
      this.handleStartIntersection,
      options
    );

    this.listEndObserver.observe(this.$refs.sentinel);
    this.listStartObserver.observe(this.$refs.topSentinel);
  }

  handleIntersection([entry]:any) {
    if (entry.isIntersecting) {
      console.log('sentinel intersecting');
    }

    if (entry.isIntersecting && !this.isLoadingMore) {
      this.loadMore();
    }
  }

  handleStartIntersection([entry]:any) {
    if (entry.isIntersecting) {
      console.log('top sentinel intersecting');
    }

    if (entry.isIntersecting && !this.isLoadingMore && !this.initialLoad && this.canHandleStartIntersection) {
      this.loadMore(true);
    }
  }

  async loadMore(isTop = false) {
    const that = this;
    this.isLoadingMore = true;
    this.localListLoading = true;
    return this.fetchMoreItems(isTop).then(() => {
      that.localListLoading = false;
      that.pageNumber++;
      const scrollContainer = that.$refs.scrollContainer;
      const tableRowHeight = 48;
      const itemsPerScreen = (Math.round(scrollContainer.clientHeight / tableRowHeight));
      if (that.filteredTableItems.length < itemsPerScreen) {
        that.shouldSearchBack = true;
        that.loadMore(that.isSearchingBack);
      }
    }).catch((error: any) => {
      console.log('Reached end of page', error);
      console.log('total items in the page ' + that.infiniteScrollList.length);
      that.canLoadMore = false;
    }).finally(() => {
      that.isLoadingMore = false;
      that.localListLoading = false;
    });
  }

  mapLocalEvents() {
    this.localEvents = this.events.map(e => {
      const formattedCurrentDate = moment(e.currentDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
      return ld.merge(e, {
        key: (e.eventId + e.googleId) + '-' + formattedCurrentDate,
        currentEventSortDate: formattedCurrentDate,
        type: this.getType(e),
        currentDateDisplay: DateTimeUtils.formatToDisplay(e.currentDate, true),
        editable: this.isEditable(e),
        color: CommonUtils.getColor(e.titleFillColor || this.eventStyling.eventLabelFillColor || '#c3d9ff')
      });
    });
    this.origEvents = ld.cloneDeep(this.localEvents);
  }

  findLoadEventsCutoff(isStart: boolean) {
    if (CommonUtils.isNotEmpty(this.localEvents)) {
      const event = ld.cloneDeep(this.localEvents).sort((a: any, b: any) => {
        const aDate = moment(a.currentEventSortDate, 'YYYY-MM-DD');
        const bDate = moment(b.currentEventSortDate, 'YYYY-MM-DD');
        if (aDate.isBefore(bDate)) {
          return isStart ? -1 : 1;
        } else if (aDate.isAfter(bDate)) {
          return isStart ? 1 : -1;
        } else {
          return 0;
        }
      }).pop();

      if (event) {
        return event.currentEventSortDate;
      }
    }

    return null;
  }

  setFilteredTableItems(items: any) {
    this.filteredTableItems = items;
  }

  async fetchMoreItems(isTop = false) {
    const that = this;

    return new Promise((resolve, reject) => {
      let start = null;
      let end = null;
      if ((!isTop && that.sortBy === 'currentEventSortDate' && !that.sortDesc) ||
          (isTop && that.sortBy === 'currentEventSortDate' && that.sortDesc) ||
          (!isTop && that.sortBy !== 'currentEventSortDate')) {
        start = that.findLoadEventsCutoff(true);
      }

      if ((isTop && that.sortBy === 'currentEventSortDate' && !that.sortDesc) ||
          (!isTop && that.sortBy === 'currentEventSortDate' && that.sortDesc) ||
          (isTop && that.sortBy !== 'currentEventSortDate')) {
        end = that.findLoadEventsCutoff(false);
      }

      that.loadEvents({
        start: start,
        end: end,
        limit: that.limit,
        addEventPage: !that.initialLoad,
        filterSelectionsShowEventTypes: that.filterSelectionsShowEventTypes,
        searchText: that.searchText
      }).then(() => {
        if (that.events.length > (that.limit * 2)) {
          that.spliceEvents({ start: isTop ? that.events.length - (that.limit + 1) : 0, deleteCount: that.limit, sortDesc: that.sortDesc });
        }

        const previousLocalEvents = ld.cloneDeep(this.localEvents);
        that.mapLocalEvents();

        if (that.initialLoad) {
          that.scrollToToday();
          that.firstLoad = false;
        }

        setTimeout(() => {
          if (!that.doneFetchingEvents) {
            that.updateEventsList(previousLocalEvents);

            resolve(that.localEvents);
          } else {
            that.updateEventsList(previousLocalEvents);

            const scrollContainer = that.$refs.scrollContainer;
            const tableRowHeight = 48;
            const itemsPerScreen = (Math.round(scrollContainer.clientHeight / tableRowHeight));
            if (that.filteredTableItems.length < itemsPerScreen) {
              that.shouldSearchBack = true;
            }

            if (that.shouldSearchBack && !that.isSearchingBack) {
              that.setDoneFetchingEvents(false);
              that.isSearchingBack = true;

              resolve(that.localEvents);
            } else {
              reject(new Error('No more items to load at the Bottom'));
            }
          }
        }, infiniteScrollDelay);
      });
    });
  }
}
